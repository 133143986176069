<template lang="pug">
pre-loader(:show="pageLoading")
  MaintenanceMode(
    v-if="getMaintenanceMode"
  )
  .wrapper(v-cloak)
    new-header(v-if="useNewDesign && isDesktop && !isHomePage" )
    v-header(v-else)
    router-view.content#app-content(:key="$route.fullPath")
    v-footer
    popup(v-if="useIE" :show="useIE")
      .content
        .title.text-center
          img(:src="resellerInfo.logo" width="90px")
        .text.text-center
          p Din webbläsare stöds dessvärre inte.
          p Ladda gärna ner den senaste versionen av Chrome, Firefox eller Safari.
        .links.text-center
          a.link(href="https://support.apple.com/sv-se/HT201541")
            img(src="~@/assets/img/safari.png")
            p Safari
          a.link(href="https://www.google.com/chrome/")
            img(src="~@/assets/img/chrome.png")
            p Chrome
          a.link(href="https://www.mozilla.org/sv-SE/firefox/new/")
            img(src="~@/assets/img/firefox.png")
            p Firefox
        .redirect.text-center
          button.btn.btn-hover.invert(@click="forceHidePopup") SURFA VIDARE!
</template>

<script>
  import {mapState, mapMutations, mapActions, mapGetters, } from 'vuex'
  import VHeader from '@/components/layout/Header'
  import NewHeader from '@/components/layout/NewHeader'
  import VFooter from '@/components/layout/Footer'
  import Popup from '@/components/helps/Popup'
  import StyleMixin from './mixins/StyleMixin'

  import {getCollectionName, getProperCollectionName, isRenault, isDesktop} from './helpers/helpers'

	require('@fortawesome/fontawesome-free/css/all.min.css')

  export default {
    metaInfo() {
      return {
        title: this.logoFont.title,
      }
    },
    data: () => ({
      pageLoading: true,
      useIE: true,
    }),
    mixins: [ StyleMixin, ],
    computed: {
      isDesktop,
      isRenault,
      ...mapState('global', ['isHomePage', ]),
      ...mapState('reseller', [
        'resellerInfo',
        'customStyle',
      ]),
      ...mapState('filter', {
        finance_form_name: state => state.finance_form_name,
        financeForms: state => state.allFinanceForms,
      }),
      ...mapState('reseller', {
        logoFont: state =>
          state.customStyle.logoFont ? state.customStyle.logoFont : "",
      }),
      ...mapGetters('global', [
        'getMaintenanceMode',
      ]),
      generalFormsCollectionName: {
        get(){
          return this.$store.state.global.generalFormsCollectionName
        },
        set(value){
          this.$store.commit('global/setGeneralFormsCollectionName', value)
        }
      },
      useNewDesign() {
        return this.resellerInfo.settings?.useNewDesign
      }
    },
    components: {
      NewHeader,
      VHeader,
      VFooter,
      Popup,
      MaintenanceMode: () => import('@/components/helps/Maintenance'),
    },
    beforeMount() {
      this.checkIE();

      this.setProperUrlName();
    },
    created() {
      document.documentElement.version = process.env.VUE_APP_COMMIT_HASH;
      this.pageLoading = false;
      
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
    },
    beforeRouteLeave(to, from, next) {
      this.pageLoading = true;

      next()
    },
    methods: {
      ...mapMutations('global', ['enablePreLoader', 'disablePreLoader', ]),
      ...mapActions('filter', ['getDefaultFinanceFormId', ]),
      forceHidePopup() {
        this.useIE = false
      },
      closePopup() {
        this.$set(this.$data, 'chooseCollectionPopup', false);
      },
      checkIE() {
        const uA = window.navigator.userAgent;
        const onlyIEorEdge =
          /msie\s|trident\/|msie\//i.test(uA) && !!window.MSInputMethodContext;

        this.useIE = onlyIEorEdge;
      },
      setProperUrlName() {
        if (!this.generalFormsCollectionName) return;

        const urlName = getCollectionName(this.generalFormsCollectionName, 'url');

        if (
          this.$router.history.pending !== null && 
          this.$router.history.pending.name !== 'notfound'
          ) {
            const type = getProperCollectionName(this.$router.history.pending.params.collectionType);

            this.generalFormsCollectionName = type;

            return;
          }

        this.$router.replace({
          name: 'home',
          params: {
            collectionType: urlName,
          }
        });
      },
    },
    watch: {
      resellerInfo: function(resellerData) {
        if (!resellerData || this.generalFormsCollectionName && this.$router.history.current.params.collectionType 
          && Object.keys(resellerData).length !== 0 && resellerData.constructor === Object 
          && this.finance_form_name) return;

        this.$root.$i18n.locale = resellerData.market.lang
        document.documentElement.lang = resellerData.market.lang
        document.documentElement.code = resellerData.market.country.code

        // if generalFormsCollectionName is empty
        // but we have parameter from Router

        if (this.$router.history.current.params.collectionType) {

          //get collection type from router and set form id
          this.generalFormsCollectionName = getProperCollectionName(this.$router.history.current.params.collectionType);

          this.getDefaultFinanceFormId(this.financeForms);

          return;
        }

        // if generalFormsCollectionName is empty
        // and we have nothing from router

        if (!this.generalFormsCollectionName) {
          const getFirstFinanceForm = (arr, name) => {
            return arr.find(item => item.type.toLowerCase() === name)
          };
          let urlName;

          // check is reseller has Private type forms
          const formPrivate = getFirstFinanceForm(this.financeForms, 'private')

          // check is reseller has Corporate type forms
          const formCorporate = getFirstFinanceForm(this.financeForms, 'corporate')

          // if we don't have any data about collection type we need to check reseller's finance forms
          // if we have Private, we're setting url to Private and gettin finance form id
          // if we don't have Private, but have Corporate, we're setting Corporate
          
          if (formPrivate && formCorporate) {
            urlName = resellerData.settings.defaultFinanceFormType.toLowerCase();
          } else if (formPrivate) {
            urlName = 'private'
          } else if (!formPrivate && formCorporate) {
            urlName = 'corporate'
          }

          this.generalFormsCollectionName = urlName;

          // setting prorep url
          this.$router.replace({
            name: 'home',
            params: {
              collectionType: getCollectionName(urlName, 'url')
            }
          });

          // setting form id to ba able to receive data
          this.getDefaultFinanceFormId(this.financeForms);
        }
      }
    }
  }
</script>

<style lang="sass">
  @import './App.sass'
</style>
